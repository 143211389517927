import React, {useState, useCallback} from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend,
} from 'chart.js';
import { Bar } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ChartDeferred from 'chartjs-plugin-deferred';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend,
  ChartDataLabels,
  ChartDeferred
);

 export default () => {
    const options = {
    plugins: {
      title: {
        display: false
      },
      legend: {
        position: 'left',
        labels: {
          color: '#fff',
          font: {
            family: '"Montserrat", sans-serif',
          },
          boxWidth: 20,
          boxHeight: 20,
        },
        reverse: true
      },
      datalabels: {
        color: '#ffffff',
        font: {
          family: '"Montserrat", sans-serif',
          weight: 'bold',
          size: 16,
        },
        anchor: 'end',
        align: 'bottom',
        offset: 2,
        formatter: (value, context) => {
          switch (context.dataset.label) {
            case "Transactional Volume":
              const one_billion = 1000000000
              const one_million = 1000000
              if (value >= one_billion) {
                return `$${value / one_billion}B`;
              } else {
                return `$${value / one_million}M`;
              }
              break;
            
            default:
              return value;
              break;
          }
        }
      },
      deferred: {
        yOffset: '50%',
        delay: 250,
      },
    },
    grouped: false,
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: '#ffffff',
          font: {
            family: '"Montserrat", sans-serif',
            size: 12
          },
          padding: -40,
          z: 2,
        },
      },
      'transactional_volume': {
        type: 'linear',
        axis: 'y',
        display: false,
      }
    },
    onResize: (chart, size) => {
      const chart_options = chart.config._config.options;
      if (size.width >= 700) {
        chart_options.plugins.datalabels.font.size = 14;
        chart_options.scales.x.ticks.font.size = 12;
      } else {
        chart_options.plugins.datalabels.font.size = 10;
        chart_options.scales.x.ticks.font.size = 8;
      }
    },
  };


  const labels = ['2012', '2014', '2016', '2018', '2020', '2021', '2022', ['2026', '(projected)']];

  const barPercentage = 1;
  const categoryPercentage = 0.9;

  const data = {
    labels,
    datasets: [
      {
        label: 'Transactional Volume',
        data: [985000000, 700000000, 1380000000, 837000000, 1130000000, 2900000000, 3000000000, 5000000000],
        barPercentage: barPercentage,
        categoryPercentage: categoryPercentage,
        backgroundColor: ['#5a9f71', '#5a9f71', '#5a9f71', '#5a9f71', '#5a9f71', '#5a9f71', '#5a9f71', '#989496', ],
        yAxisID: 'transactional_volume',
        align: 'left',
      }
    ],
  };

  return (
    <Bar options={options} data={data} />
  );
}