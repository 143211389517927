import React from 'react'
import { Container } from 'react-bootstrap'
import Image from '../components/image'
import DispositionsChart from '../components/dispositions-chart'

export default (props) => {
  return (
    <section className="section section--dark section--bg-image section--shadowed">
      <Container>
        <div className="section__content">
          <h2 className="kh-h2 kh-h2--xl">{props.headline}</h2>
          <div className="acqdis-layout">
            <div className="acqdis-images">
              <div className="acqdis-images__image">
                <Image {...props.imageOne.localFile.childImageSharp.fluid} alt={props.imageOne.altText} />
                <span className="acqdis-images__caption" dangerouslySetInnerHTML={{__html: props.imageOne.caption}}></span>
              </div>
              <div className="acqdis-images__image">
                <Image {...props.imageTwo.localFile.childImageSharp.fluid} alt={props.imageTwo.altText} />
                <span className="acqdis-images__caption" dangerouslySetInnerHTML={{__html: props.imageTwo.caption}}></span>
              </div>
            </div>
            <div className="acqdis-chart">
              <DispositionsChart />
            </div>
            <div className="acqdis-entries">
              {props.acquisition.map((object) => (
                  <div className="acqdis-entry">
                    <div className="acqdis-entry__value">{object.value}</div>
                    <div className="acqdis-entry__content" dangerouslySetInnerHTML={{__html: object.content}}></div>
                    <div className="acqdis-entry__year">
                      {object.year}
                      <div className="acqdis-entry__year-subtext">{object.yearSubtext}</div>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
}