import { useStaticQuery, graphql } from 'gatsby'

export const usePage = () => {
  const { allWp } = useStaticQuery(
    graphql`
      query {
        allWp {
          nodes {
            growthContent {
              acfGrowthContent {
                history {
                  content
                  headline {
                    largeCopy
                    smallCopy
                  }
                  stats {
                    label
                    value
                  }
                  statsHeadline {
                    largeCopy
                    smallCopy
                  }
                }
                continuedGrowth {
                  headline
                  quote {
                    citation
                    subtext
                    content
                    photo {
                      altText
                      localFile {
                        childImageSharp {
                          id
                          fluid {
                            sizes
                            srcSet
                            src
                          }
                          resize {
                            src
                            tracedSVG
                            width
                            height
                            aspectRatio
                            originalName
                          }
                        }
                      }
                    }
                  }
                }
                breakingRecords {
                  headline
                  quote {
                    citation
                    subtext
                    content
                    photo {
                      altText
                      localFile {
                        childImageSharp {
                          id
                          fluid {
                            sizes
                            srcSet
                            src
                          }
                          resize {
                            src
                            tracedSVG
                            width
                            height
                            aspectRatio
                            originalName
                          }
                        }
                      }
                    }
                  }
                }
                acquisitions {
                  imageOne {
                    altText
                    caption
                    localFile {
                      childImageSharp {
                        id
                        fluid {
                          sizes
                          srcSet
                          src
                        }
                        resize {
                          src
                          tracedSVG
                          width
                          height
                          aspectRatio
                          originalName
                        }
                      }
                    }
                  }
                  imageTwo {
                    altText
                    caption
                    localFile {
                      childImageSharp {
                        id
                        fluid {
                          sizes
                          srcSet
                          src
                        }
                        resize {
                          src
                          tracedSVG
                          width
                          height
                          aspectRatio
                          originalName
                        }
                      }
                    }
                  }
                  acquisition {
                    content
                    value
                    year
                    yearSubtext
                  }
                  headline
                }
                dispositions {
                  imageOne {
                    altText
                    caption
                    localFile {
                      childImageSharp {
                        id
                        fluid {
                          sizes
                          srcSet
                          src
                        }
                        resize {
                          src
                          tracedSVG
                          width
                          height
                          aspectRatio
                          originalName
                        }
                      }
                    }
                  }
                  imageTwo {
                    altText
                    caption
                    localFile {
                      childImageSharp {
                        id
                        fluid {
                          sizes
                          srcSet
                          src
                        }
                        resize {
                          src
                          tracedSVG
                          width
                          height
                          aspectRatio
                          originalName
                        }
                      }
                    }
                  }
                  acquisition {
                    content
                    value
                    year
                    yearSubtext
                  }
                  headline
                }
              }
            }
          }
        }
      }
    `
  )
  return allWp.nodes[0].growthContent.acfGrowthContent;
}
