import * as React from "react"
import {useState, useEffect} from "react"

// components
import Header from "../components/header"
import Banner from "../components/banner"
import MediaBox from "../components/media-box"
import MediaEmbed from "../components/media-embed"
import Footer from "../components/footer"
import SEO from "../components/seo"

import SectionHistory from '../sections/history'
import SectionBreakingRecords from '../sections/breaking-records'
import SectionAcquisitions from '../sections/acquisitions'
import SectionDispositions from '../sections/dispositions'
import SectionGrowth from '../sections/growth'

import { Container, Button, Modal } from 'react-bootstrap'
import BackgroundImage from 'gatsby-background-image'
import { renderToString } from 'react-dom/server';
import { Parallax, Background } from 'react-parallax';

import { usePage } from "../hooks/continued-growth"

const Page = ({ pageContext }) => {
  const post = pageContext
  const content = usePage()
  return (
    <main>
        <SEO
          title={post.title}
          description={post.acfSEO.metaDescription}

          />
        <Header />
        
        <SectionHistory {...content.history} paddingTop="clamp(125px, 25vmin, 300px)" />
        
        <SectionBreakingRecords {...content.breakingRecords} />
        
        <SectionAcquisitions {...content.acquisitions} />
        
        <SectionDispositions {...content.dispositions} />
        
        <SectionGrowth {...content.continuedGrowth} />
        
        <Footer />
    </main>
  )
}

export default Page
