import React from 'react'
import { Container } from 'react-bootstrap'
import { Parallax, Background } from 'react-parallax';
import Image from '../components/image'
import BreakingRecordsChart from '../components/breaking-records-chart'

import backgroundImage from '../images/breaking-records-background.jpg'

export default (props) => {
  return (
    <section className="section section--dark section--shadowed">
      <Parallax strength={500}>
        <Background className="custom-bg">
          <img src={backgroundImage} style={{width: "100%"}} />
        </Background>
        <Container>
          <div className="section__content">
            <div>
              <h2 className="kh-h2 kh-h2--xl">{props.headline}</h2>
              <div className="photo-quote-cluster-3">
                <Image {...props.quote.photo.localFile.childImageSharp.fluid} className="photo-quote-cluster-3__image" />
                <blockquote className="photo-quote-cluster-3__blockquote kh-blockquote">
                  <p>
                    {props.quote.content}
                  </p>
                  <footer className="kh-blockquote__footer">
                    <span className="kh-blockquote__name">- {props.quote.citation}</span>
                    <span className="kh-blockquote__position">{props.quote.subtext}</span>
                  </footer>
                </blockquote>
              </div>
            </div>
            <BreakingRecordsChart />
          </div>
        </Container>
      </Parallax>
    </section>
  )
}