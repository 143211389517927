import React, {useState, useCallback} from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend,
} from 'chart.js';
import { Bar } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ChartDeferred from 'chartjs-plugin-deferred';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend,
  ChartDataLabels,
  ChartDeferred
);

 export default () => {
  const options = {
    // indexAxis: 'y',
    plugins: {
      title: {
        display: false
      },
      legend: {
        // position: 'bottom',
        labels: {
          color: '#fff',
          font: {
            family: '"Montserrat", sans-serif',
          },
          boxWidth: 20,
          boxHeight: 20,
        },
        reverse: true
      },
      datalabels: {
        color: '#ffffff',
        font: {
          family: '"Montserrat", sans-serif',
          weight: 'bold',
          size: 16,
        },
        anchor: 'end',
        align: 'bottom',
        offset: 2,
        formatter: (value, context) => {
          return `$${value}M`
        }
      },
      deferred: {
        yOffset: '50%',
        delay: 250,
      },
    },
    grouped: false,
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: '#ffffff',
          font: {
            family: '"Montserrat", sans-serif',
            size: 12
          },
          padding: -40,
          z: 2,
        },
      },
      'transactional_volume': {
        type: 'linear',
        axis: 'y',
        display: false,
      }
    },
  };


  const labels = ['2020', '2021', '2022', '2023'];

  const barPercentage = 1;
  const categoryPercentage = 0.95;

  const data = {
    labels,
    datasets: [
      {
        label: 'Avg. Acquisition Size',
        data: [56, 72, 92, 91],
        barPercentage: barPercentage,
        categoryPercentage: categoryPercentage,
        backgroundColor: ['#5a9f71'],
        yAxisID: 'transactional_volume',
        align: 'left',
      }
    ],
  };

  return (
    <Bar options={options} data={data} />
  );
}